<form [formGroup]="form" novalidate autocomplete="off">
  <div class="modal-dialog-header">
    <button
      type="button"
      tabindex="-1"
      class="close float-right"
      data-dismiss="modal"
      aria-label="Close"
      (click)="cancel()"
      title="Close form and discard any changes."
    >
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
    <h3 mat-dialog-title>{{ data.title }}</h3>
  </div>

  <mat-dialog-content class="modal-dialog-content">
    <mat-tab-group>
      <mat-tab label="Details">
        <fieldset [disabled]="!data.currentUserCanEdit">
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label for="partId">Part</label>
              <a
                *ngIf="data.currentUserCanEdit"
                (click)="openNewPartModal()"
                style="margin-top: 2px;"
                class="float-right small pointer text-primary"
                ><i class="far fa-plus"></i> New Part</a
              >
              <kendo-combobox
                [data]="partOptions$ | async"
                [size]="'small'"
                formControlName="partId"
                [kendoDropDownFilter]="filterSettings"
                [textField]="'text'"
                [valueField]="'value'"
                [valuePrimitive]="true"
                [disabled]="!data.currentUserCanEdit"
                [virtual]="{ itemHeight: 28 }"
                [filterable]="true"
              >
              </kendo-combobox>
              <p *ngIf="form.get('partId').invalid && form.get('partId').dirty; else description" class="text-danger">
                A Part for this item is required
              </p>
              <ng-template #description>
                <small class="form-text text-muted">
                  {{ selectedPartDescription$ | async }}
                </small>
              </ng-template>
            </div>
            <div class="form-group col-sm-3">
              <label for="serialNumber">Model Number</label>
              <input formControlName="modelNumber" type="text" class="form-control form-control-sm" />
            </div>
            <div class="form-group col-sm-2">
              <label for="status">Status</label>
              <select formControlName="status" class="form-control form-control-sm">
                <option *ngFor="let status of itemStatuses" [ngValue]="status">{{ status }}</option>
              </select>
              <p *ngIf="form.get('status').invalid && form.get('status').dirty" class="text-danger">
                A Status is required
              </p>
            </div>
            <div class="form-group col-sm-2">
              <label for="projectId">Project</label>
              <select formControlName="projectId" class="form-control form-control-sm">
                <option *ngFor="let project of data.projects" [ngValue]="project.id">
                  {{ project.code }}
                </option>
              </select>
              <p *ngIf="form.get('projectId').invalid && form.get('projectId').dirty" class="text-danger">
                A Project for this item is required
              </p>
            </div>
            <div class="form-group col-sm-2">
              <label for="purchaseOrderId">Receiving Doc</label>
              <select formControlName="purchaseOrderId" class="form-control form-control-sm">
                <option *ngFor="let purchaseOrder of data.purchaseOrders" [ngValue]="purchaseOrder.id">
                  {{ purchaseOrder.number }}
                </option>
              </select>
              <p *ngIf="form.get('purchaseOrderId').invalid && form.get('purchaseOrderId').dirty" class="text-danger">
                A Receiving Document for this item is required
              </p>
            </div>
          </div>
          <div class="form-row">
            <div class="col-sm-3">
              <label for="lruId">NHA ID</label>
              <a
                *ngIf="form.get('nhaId').value"
                (click)="openNhaDialog(form.get('nhaId').value)"
                style="margin-top: 2px;"
                class="float-right small pointer text-primary"
                >Open</a
              >
              <kendo-multicolumncombobox
                #list
                [data]="filteredNhas"
                [listHeight]="300"
                textField="smxId"
                valueField="id"
                [valuePrimitive]="true"
                formControlName="nhaId"
                [virtual]="{ itemHeight: 36 }"
                [size]="'small'"
                [filterable]="true"
                (filterChange)="handleFilterChange($event)"
                [size]="'small'"
              >
                <kendo-combobox-column field="smxId" title="SMX ID" [width]="200"></kendo-combobox-column>
                <kendo-combobox-column field="partNumber" title="Part #" [width]="200"> </kendo-combobox-column>
                <kendo-combobox-column field="manufacturerName" title="Manufacturer" [width]="200">
                </kendo-combobox-column>
                <kendo-combobox-column field="partDescription" title="Description" [width]="200">
                </kendo-combobox-column>
                <ng-template kendoMultiColumnComboBoxFooterTemplate>
                  <strong> {{ filteredNhas.length }} records in total </strong>
                </ng-template>
              </kendo-multicolumncombobox>
            </div>
            <div class="form-group col-sm-2">
              <label for="orderQuantity">Order Quantity</label>
              <input formControlName="orderQuantity" type="number" class="form-control form-control-sm" />
              <p *ngIf="form.get('orderQuantity').invalid && form.get('orderQuantity').dirty" class="text-danger">
                A Quantity for this item is required
              </p>
            </div>
            <div class="form-group col-sm-2">
              <label for="unitPrice">Unit Price</label>
              <input currencyMask formControlName="unitPrice" class="form-control form-control-sm" />
              <p *ngIf="form.get('unitPrice').invalid && form.get('unitPrice').dirty" class="text-danger">
                A Unit Price for this item is required
              </p>
            </div>
            <div class="form-group col-sm-2">
              <label for="totalCost">Total Cost</label>
              <input currencyMask formControlName="totalCost" class="form-control form-control-sm" />
              <p *ngIf="form.get('totalCost').invalid && form.get('totalCost').dirty" class="text-danger">
                A Total Cost for this item is required
              </p>
            </div>
            <div class="form-group col-sm-3">
              <label for="shippingOrderId">Shipping Order</label>
              <select formControlName="shippingOrderId" class="form-control form-control-sm">
                <option [ngValue]="null"></option>
                <option *ngFor="let shippingOrder of data.shippingOrders" [ngValue]="shippingOrder.id">
                  {{ shippingOrder.number }}
                </option>
              </select>
              <p *ngIf="form.get('shippingOrderId').invalid && form.get('shippingOrderId').dirty" class="text-danger">
                A Shipping Order for this item is required
              </p>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-3">
              <label for="serialNumber">Serial Number</label>
              <input formControlName="serialNumber" type="text" class="form-control form-control-sm" />
            </div>
            <div class="form-group col-sm-2">
              <label for="serialNumber">SMX ID #</label>
              <!--Display a dropdown with the next sequential serial options on the new form-->
              <kendo-combobox
                *ngIf="!this.isEditForm"
                [data]="data.nextSequentialSmxIds"
                [size]="'small'"
                formControlName="smxId"
                [kendoDropDownFilter]="filterSettings"
                [disabled]="!data.currentUserCanEdit"
                [filterable]="true"
                [allowCustom]="true"
                title="These values are the next available sequential IDs available"
              >
              </kendo-combobox>
              <input *ngIf="this.isEditForm" formControlName="smxId" type="text" class="form-control form-control-sm" />
            </div>
            <div class="form-group col-sm-2">
              <label for="itarEccn">ITAAR/ECCN</label>
              <input formControlName="itarEccn" type="text" class="form-control form-control-sm" />
            </div>

            <div class="form-group col-sm-2">
              <label for="hsCode">H/S Code</label>
              <input formControlName="hsCode" type="text" class="form-control form-control-sm" />
            </div>
            <div class="form-group col-sm-1">
              <label for="cci">CCI</label>
              <select formControlName="cci" class="form-control form-control-sm">
                <option [ngValue]="false">No</option>
                <option [ngValue]="true">Yes</option>
              </select>
            </div>
            <div class="form-group col-sm-2" title="Condition Code">
              <label for="conditionCode">Condition Code</label>
              <select formControlName="conditionCode" class="form-control form-control-sm">
                <option [ngValue]="null"></option>
                <option *ngFor="let conditionCode of data.conditionCodeOptions" [ngValue]="conditionCode">{{
                  conditionCode
                }}</option>
              </select>
            </div>
          </div>
          <small class="form-text text-muted">
            <fa-icon [icon]="faInfoCircle"></fa-icon> If multiple serialized assets are required for similar items you
            can save this item and then select the clone button to the right of the item to pre-populate all Item
            information. </small
          ><br />
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label for="name">Site</label>
              <select formControlName="siteId" class="form-control form-control-sm">
                <option *ngFor="let site of data.sites" [ngValue]="site.id">{{ site.name }}</option>
              </select>
              <p *ngIf="form.get('siteId').invalid && form.get('siteId').dirty" class="text-danger">
                An SMARTTRONIX Site is required
              </p>
            </div>
            <div class="form-group col-sm-4">
              <label for="buildingId">Building</label>
              <a
                *ngIf="data.currentUserCanEdit"
                (click)="openNewBuildingModal()"
                style="margin-top: 2px;"
                class="float-right small pointer text-primary"
                ><i class="far fa-plus"></i> New Building</a
              >
              <select formControlName="buildingId" class="form-control form-control-sm">
                <option [ngValue]="null"></option>
                <option *ngFor="let building of buildings$ | async" [ngValue]="building.id">{{ building.name }}</option>
              </select>
            </div>
            <div class="form-group col-sm-4">
              <label for="roomId">Room</label>
              <a
                *ngIf="data.currentUserCanEdit"
                (click)="openNewRoomsModal()"
                style="margin-top: 2px;"
                class="float-right small pointer text-primary"
                ><i class="far fa-plus"></i> New Room</a
              >
              <select formControlName="roomId" class="form-control form-control-sm">
                <option [ngValue]="null"></option>
                <option *ngFor="let room of rooms$ | async" [ngValue]="room.id">{{ room.name }}</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-4">
              <label for="roomId">Location</label>
              <input formControlName="location" type="text" class="form-control form-control-sm" />
            </div>
            <div class="form-group col-sm-3">
              <label for="assignedToId">Assigned To</label>
              <select formControlName="assignedToId" class="form-control form-control-sm">
                <option [ngValue]="null"></option>
                <option *ngFor="let custodian of data.custodians" [ngValue]="custodian.id">
                  {{ custodian.firstName }} {{ custodian.lastName }}
                </option>
              </select>
            </div>
            <div class="form-group col-sm-3">
              <label for="componentType">Component Type</label>
              <select formControlName="componentType" class="form-control form-control-sm">
                <option [ngValue]="null"></option>
                <option *ngFor="let componentType of componentTypes" [ngValue]="componentType">{{
                  componentType
                }}</option>
              </select>
            </div>
            <div class="form-group col-sm-2">
              <label for="warrantyExp">Warranty Exp.</label>
              <input formControlName="warrantyExp" type="date" class="form-control form-control-sm" />
            </div>
          </div>
          <div class="form-row">
            <div class="form-group col-sm-12">
              <label for="comments">Comments</label>
              <textarea autosize formControlName="comments" class="form-control form-control-sm"></textarea>
            </div>
          </div>
        </fieldset>

        <div class="po-header-row" *ngIf="(attachments$ | async).length > 0">
          <div class="po-details">
            <span class="label">Attachments</span>
            <div class="row">
              <div *ngFor="let attachment of attachments$ | async" class="col-sm-6 attachments">
                <a download href="/api/ItemAttachments/{{ attachment.id }}" class="attachment">
                  <i class="far fa-paperclip"></i> {{ attachment.fileName }}</a
                >&nbsp;
                <button
                  *ngIf="data.currentUserCanEdit"
                  title="Delete Attachment"
                  (click)="deleteAttachment(attachment.id)"
                  class="btn btn-outline-danger btn-sm"
                >
                  <i class="far fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Child Components" *ngIf="data.childComponents.length > 0">
        <div *ngIf="data.childComponents.length > 0; else noChildren">
          <div class="card" *ngFor="let childComponent of data.childComponents">
            <div class="card-header">
              <div class="row">
                <div class="col">
                  SMX ID:
                  <a class="pointer text-primary" (click)="openNhaDialog(childComponent.id)">{{
                    childComponent.smxId
                  }}</a>
                </div>
                <div class="col">Part: {{ childComponent.partNumber }}</div>
                <div class="col">Serial: {{ childComponent.serialNumber }}</div>
                <div class="col">Status: {{ childComponent.status }}</div>
              </div>
            </div>
          </div>
        </div>
        <ng-template #noChildren>
          <div class="card">
            <div class="card-body">
              <p>No child components found</p>
            </div>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="History">
        <div *ngIf="(versionHistory$ | async)?.length > 0; else noHistory">
          <div class="card history-card" *ngFor="let version of versionHistory$ | async">
            <div class="card-header">
              <div class="row bold">
                <div class="col">Change: {{ version.version }}</div>
                <div class="col">Author: {{ version.changedBy }}</div>
                <div class="col">Date: {{ version.periodStart | date: 'short' }}</div>
              </div>
            </div>
            <table class="table table-sm card-body">
              <thead>
                <th>Field</th>
                <th>From</th>
                <th>To</th>
              </thead>
              <tbody>
                <tr *ngFor="let change of version.changes">
                  <td>{{ change.field }}</td>
                  <td>{{ change.oldValue || '-' }}</td>
                  <td>{{ change.newValue || '-' }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <ng-template #noHistory>
          <div class="card">
            <div class="card-body">
              <p>No history found</p>
            </div>
          </div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>

    <!-- Hidden barcode that we use to print-->
    <div style="display: none;">
      <div *ngIf="isEditForm" id="barcode-label">
        <div class="label-row" *ngIf="form.get('siteId').value <= 4 || form.get('siteId').value >= 7">
          U.S. GOVERNMENT PROPERTY
        </div>
        <div class="label-row" *ngIf="form.get('siteId').value === 5">SMX Corporate Asset</div>
        <div class="label-row" *ngIf="form.get('siteId').value === 6">StarShield Asset</div>
        <div class="label-row">{{ selectedPartDescription$ | async }}</div>
        <div class="label-row">
          SN: {{ form.get('serialNumber').value }}
          <ng-container *ngIf="selectedPartNumber$ | async as selectedPartNumber">
            | PN: {{ selectedPartNumber }}</ng-container
          >
        </div>
        <div class="label-row">
          <ngx-barcode
            [bc-value]="form.get('smxId').value"
            [bc-display-value]="true"
            [bc-height]="45"
            [bc-font-size]="12"
            [bc-margin]="0"
          ></ngx-barcode>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <div class="modal-dialog-footer">
    <button type="button" class="btn btn-default float-left btn-sm" (click)="cancel()">
      <i class="far fa-times"></i> {{ data.currentUserCanEdit ? 'Cancel' : 'Close' }}
    </button>
    <div class="float-right">
      <button
        *ngIf="isEditForm"
        type="button"
        printSectionId="barcode-label"
        ngxPrint
        styleSheetFile="../../../assets/css/print-barcode-label.css"
        class="btn btn-primary btn-sm"
      >
        <fa-icon [icon]="faBarcode"></fa-icon> Create Barcode
      </button>
      &nbsp;
      <button
        *ngIf="!!form.get('id')?.value"
        type="button"
        (click)="openUploadAddAttachmentDialog()"
        class="btn btn-primary btn-sm"
        title="Upload attachment for this item"
      >
        <fa-icon [icon]="faPaperclip"></fa-icon> Add Attachment
      </button>
      &nbsp;
      <button
        *ngIf="displayCheckInButton"
        type="button"
        (submitIfValid)="checkInItem()"
        [form]="form"
        class="btn btn-primary btn-sm"
        title="Check-in item from assigned user.  This will remove the assigned user and reset the status to In Stock."
      >
        <fa-icon [icon]="faTimes"></fa-icon> Check-In
      </button>
      &nbsp;
      <button
        *ngIf="data.currentUserCanEdit"
        type="button"
        (submitIfValid)="save()"
        [form]="form"
        class="btn btn-primary btn-sm"
      >
        <fa-icon [icon]="faSave"></fa-icon> Save
      </button>
    </div>
  </div>
</form>
