<div class="card" id="items-container">
  <h5 class="card-header diog-grid-header">
    <div class="card-title">
      Items <span *ngIf="!(loading$ | async)" class="badge badge-pill badge-secondary">{{ gridCount }}</span>
    </div>
    <div class="card-controls d-inline-flex">
      <ng-form [formGroup]="form">
        <kendo-switch
          [onLabel]="'Available'"
          [offLabel]="'Shipped'"
          formControlName="displayActiveItems"
          style="width: 7em;"
        ></kendo-switch>
      </ng-form>
      <app-grid-filter-input
        [ngModel]="filterValue$ | async"
        #filter
        (keyup)="updateFilter(filter.value)"
        style="margin-right: 10px;"
      ></app-grid-filter-input>
      <ng-form [formGroup]="form" *ngIf="(userSites$ | async)?.length > 1">
        <kendo-combobox
          [placeholder]="'Site Filter'"
          id="site-picker"
          [data]="userSites$ | async"
          [valueField]="'id'"
          [textField]="'name'"
          [filterable]="true"
          formControlName="siteFilter"
          [valuePrimitive]="true"
          [clearButton]="true"
          [suggest]="false"
        >
        </kendo-combobox>
        &nbsp;
      </ng-form>
      <div>
        &nbsp;

        <button
          type="button"
          [disabled]="loading$ | async"
          (click)="exportToExcel(grid)"
          class="btn btn-outline-success"
        >
          <i class="far fa-file-excel"></i> Export
        </button>
        &nbsp;
      </div>
      <div>
        <button
          *ngIf="currentUserCanEdit$ | async"
          type="button"
          (click)="openNewDialog()"
          class="btn btn-outline-primary"
        >
          <i class="far fa-plus"></i> New
        </button>
      </div>
    </div>
  </h5>

  <kendo-grid
    #grid
    [data]="gridData$ | async"
    [rowHeight]="rowHeight"
    style="height: calc(100vh - 180px);"
    [resizable]="true"
    [sortable]="{
      allowUnsort: false,
      mode: 'single'
    }"
    [sort]="sort$ | async"
    [scrollable]="'virtual'"
    [loading]="loading$ | async"
    [pageable]="true"
    [pageSize]="pageSize"
    [skip]="skip$ | async"
    (sortChange)="sortChange($event)"
    (pageChange)="pageChange($event)"
    (excelExport)="onExcelExport($event)"
    [trackBy]="trackBy"
  >
    <kendo-grid-column [width]="130" title="Actions">
      <ng-template kendoGridCellTemplate let-item>
        <div class="actions no-wrap">
          <button
            (click)="openEditDialog(item.id)"
            title="Edit"
            type="button"
            class="btn btn-outline-primary btn-sm no-wrap"
          >
            <i class="far fa-edit"></i>
          </button>
          <ng-container *ngIf="currentUserCanEdit$ | async">
            <button
              *ngIf="currentUserIsAdmin$ | async"
              (click)="delete(item)"
              title="Delete"
              type="button"
              class="btn btn-outline-danger btn-sm no-wrap"
            >
              <i class="far fa-trash-alt"></i>
            </button>

            <button
              *ngIf="cartIds.includes(item.id); else addToCartButton"
              (click)="removeFromCart(item.id)"
              title="Item already in cart. Click to remove."
              type="button"
              class="btn btn-outline-warning btn-sm no-wrap"
            >
              <i class="far fa-minus"></i>
            </button>
            <ng-template #addToCartButton>
              <button
                (click)="addToCart(item.id)"
                title="Add to Cart"
                type="button"
                class="btn btn-outline-success btn-sm no-wrap"
              >
                <i class="far fa-plus"></i>
              </button>
            </ng-template>
          </ng-container>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100" field="status" title="Status"></kendo-grid-column>
    <kendo-grid-column [width]="100" field="purchaseOrderNumber" title="RD #"></kendo-grid-column>
    <kendo-grid-column [width]="150" field="smxId" title="SMX ID #" class="overflow-ellipsis"></kendo-grid-column>
    <kendo-grid-column [width]="160" field="nhaSmxId" title="NHA ID #">
      <ng-template kendoGridCellTemplate let-item>
        <div *ngIf="item.nhaId">
          <a class="pointer text-primary" (click)="openEditDialog(item.nhaId)">
            {{ item.nhaSmxId || 'n/a' }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="160" field="partNumber" title="Part #">
      <ng-template kendoGridCellTemplate let-item>
        <div>
          <a class="pointer text-primary" (click)="openEditDialog(item.id)">
            {{ item.partNumber || 'n/a' }}
          </a>
        </div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="150" field="partDescription" title="Part Desc">
      <ng-template kendoGridCellTemplate let-item>
        <div [title]="item.partDescription" class="overflow-ellipsis">{{ item.partDescription }}</div>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100" class="overflow-ellipsis" field="modelNumber" title="Model #"></kendo-grid-column>
    <kendo-grid-column
      [width]="100"
      class="overflow-ellipsis"
      field="serialNumber"
      title="Serial #"
    ></kendo-grid-column>
    <kendo-grid-column [width]="80" field="orderQuantity" title="Qty"></kendo-grid-column>
    <kendo-grid-column [width]="60" field="partUnitOfIssue" title="U/I"></kendo-grid-column>
    <kendo-grid-column [width]="120" field="unitPrice" title="Unit Price">
      <ng-template kendoGridCellTemplate let-item>
        {{ item.unitPrice | currency }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="120" field="totalCost" title="Total Cost">
      <ng-template kendoGridCellTemplate let-item>
        {{ item.totalCost | currency }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="110" field="siteName" title="Site"></kendo-grid-column>
    <kendo-grid-column
      [width]="170"
      field="buildingName"
      title="Building"
      class="overflow-ellipsis"
    ></kendo-grid-column>
    <kendo-grid-column [width]="100" field="roomName" title="Room"></kendo-grid-column>
    <kendo-grid-column [width]="150" field="location" title="Location"></kendo-grid-column>
    <kendo-grid-column [width]="130" field="purchaseOrderClassification" title="Classification"></kendo-grid-column>
    <kendo-grid-column [width]="130" field="conditionCode" title="Condition Code"></kendo-grid-column>
    <kendo-grid-column [width]="130" field="cci" title="CCI">
      <ng-template kendoGridCellTemplate let-item>
        {{ item.cci ? 'Yes' : 'No' }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="100" field="partNsn" title="NSN"></kendo-grid-column>
    <kendo-grid-column [width]="120" field="itarEccn" title="ITAR/ECCN"></kendo-grid-column>
    <kendo-grid-column [width]="120" field="hsCode" title="H/S Code"></kendo-grid-column>
    <kendo-grid-column [width]="130" field="warrantyExp" title="Warranty Exp.">
      <ng-template kendoGridCellTemplate let-item>
        {{ item.warrantyExp | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column [width]="110" field="manufacturerName" class="overflow-ellipsis" title="Manufacturer">
    </kendo-grid-column>
    <kendo-grid-column [width]="100" field="vendorName" class="overflow-ellipsis" title="Vendor"></kendo-grid-column>
    <kendo-grid-column
      [width]="100"
      field="assignedTo"
      class="overflow-ellipsis"
      title="Assigned To"
    ></kendo-grid-column>
    <kendo-grid-column [width]="70" field="projectCode" title="Project"></kendo-grid-column>
    <kendo-grid-column
      [width]="130"
      field="projectContractNumber"
      title="Contract #"
      class="overflow-ellipsis"
    ></kendo-grid-column>
    <kendo-grid-column
      [width]="70"
      field="purchaseOrderRipNumber"
      title="RIP"
      class="overflow-ellipsis"
    ></kendo-grid-column>
    <kendo-grid-column
      [width]="150"
      field="componentType"
      title="Component Type"
      class="overflow-ellipsis"
    ></kendo-grid-column>
    <kendo-grid-column [width]="130" field="comments" title="Comments" class="overflow-ellipsis"></kendo-grid-column>
    <kendo-grid-column [width]="130" field="modifiedDate" title="Modified">
      <ng-template kendoGridCellTemplate let-item>
        {{ item.modifiedDate | date }}
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-excel [fetchData]="export"></kendo-grid-excel>
  </kendo-grid>
</div>
